import {
  getQueryParameters,
  isATSOrganization,
  isHRNotDisabled,
} from "utils/utils"
import { isCandidXMode } from "utils/productUtils"
import { ENGLISH_LANGUAGE_CODE } from "utils/constants"

export const getRareFormConfigurationFromCandidate = candidate => {
  return JSON.parse(
    (candidate && candidate.rareApplicationFormConfiguration) || "{}"
  )
}

export const getFormConfigurationFromTemplate = template => {
  const { language } = getQueryParameters()
  const defaultConfiguration = template
    ? JSON.parse(template.formConfiguration || "{}")
    : {}
  if (language && language !== ENGLISH_LANGUAGE_CODE) {
    const configuration = template.jsonConfigurations.find(
      x => x.languageCode === language
    )?.configuration
    if (configuration) {
      const configurationJSON = JSON.parse(configuration)
      return {
        ...defaultConfiguration,
        ...configurationJSON,
      }
    }
  }
  return defaultConfiguration
}

export const getFormConfigurationFromCandidate = candidate =>
  getFormConfigurationFromRole(candidate?.role)

export const getFormConfigurationFromRole = role => {
  if (role?.template) {
    return getFormConfigurationFromTemplate(role.template)
  }
  return role ? JSON.parse(role.formConfiguration || "{}") : {}
}

export const getFormConfigurationFromOrganization = organization => {
  return organization
    ? JSON.parse(
        organization.defaultConfiguration ||
          organization.formConfiguration ||
          "{}"
      )
    : {}
}

export const getScreenConfiguration = (organization, screeningView) => {
  const views = getFormConfigurationFromOrganization(organization).views
  const view =
    screeningView && views
      ? views.find(x => parseInt(x.id) === parseInt(screeningView.id)) || {}
      : {}
  return view ? view.configuration || {} : {}
}

export const getDefaultScreenConfiguration = organization => {
  const views =
    organization && getFormConfigurationFromOrganization(organization).views
  return (views && (views.find(x => x.default) || views[0])) || {}
}

export const showEvents = organization => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)
  return isATSOrganization(organization)
    ? isHRNotDisabled(oconfiguration, "events")
    : isCandidXMode()
}
